import { createApp } from "vue";
//import Vue from 'vue'
import App from "./App.vue";
import router from "./router";
//import { RecycleScroller, DynamicScroller } from 'vue-virtual-scroller'

import { IonicVue } from "@ionic/vue";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

/* import libraries for mathquill */
import "katex/dist/katex.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "mathquill4quill/mathquill4quill.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/milage-variables.css";
import "./theme/milage-variables-web.css";

import pinia from "@/plugins/piniaStore";
import "swiper/css";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("service_worker.js").then(function () {
    console.log("service worker registered");
  });
}

if (process.env.NODE_ENV === "production") {
  console.log = function () {
    return "";
  };
}
const app = createApp(App).use(IonicVue).use(pinia).use(router);
// .use(RecycleScroller);

router.isReady().then(() => {
  app.mount("#app");
});

defineCustomElements(window);
