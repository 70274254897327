<template>
  <ion-page @click.stop="" class="modal-primary">
    <img :src="x_icon" class="close-icon" @click="dismiss" />

    <ion-content>
      <div class="pdf-wrapper">
        <PdfViewer
          v-if="getViewerType(type) === 'pdf' && resolvedSrc"
          :source="resolvedSrc"
          :full-size="true"
          :page="currentPage" @page-changed="handlePageChanged" 
        />
        <VideoViewer
        v-if="getViewerType(type) === 'video'"
        :source="resolvedSrc"
        :zoomable="false"
      />
      <ImageViewer
        v-if="getViewerType(type) === 'image'"
        :source="resolvedSrc"
        :zoomable="false"
        :rotation="rotation" 
      />
      <AudioPlayer
        v-if="getViewerType(type) === 'audio'"
        :source="resolvedSrc"
        :type="type"
        thumbnail="/assets/Soundwave.svg"
        :zoomable="false"
      />
      </div>

    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonContent,
  modalController,
  IonPage,
} from "@ionic/vue";
import { ref, onMounted, computed } from "vue";
import { getFile, getIcon } from "@/helpers/api";
import { useWorksheetData } from "@/modules/a.worksheets/stores/worksheetDataStore";
import PdfViewer from "@/globals/components/PdfViewer/PdfViewer.vue";
import { computedAsync } from "@vueuse/core";
import { getViewerType, Viewer, resolveMimeType, MimeType } from "@/globals/helpers/media/mimeTypeUtils";
import VideoViewer from "@/modules/a.worksheets/views/components/VideoViewer.vue";
import ImageViewer from "@/modules/a.worksheets/views/components/ImageViewer.vue";
import AudioPlayer from "@/modules/a.worksheets/views/components/AudioPlayer.vue";

defineProps({
  modalBoxTitle: String,
  selectedValue: String,
  rightButtonLabel: String,
  middleButtonLabel: String,
  leftButtonLabel: String,
});
const worksheetDataStore = useWorksheetData();

const dismiss = () => modalController.dismiss(null, "close");

const left_arrow = ref<string>();
const x_icon = ref<string>();
onMounted(async () => {
  left_arrow.value = await getIcon("1010", "left_arrow");
  x_icon.value = await getIcon("1010", "close");
});

const type = computed(() => resolveMimeType(worksheetDataStore.extra_info?.filetype ?? ''))

const resolvedSrc = computedAsync(async () => {
  console.log("worksheetDataStore.extra_info: ", worksheetDataStore.extra_info)

  if (worksheetDataStore.extra_info) {
    const src = await getFile(
      worksheetDataStore.extra_info.filepath,
      worksheetDataStore.extra_info.filename,
      worksheetDataStore.extra_info.filetype
    );
    console.log("resolved src: ", src)
    return src ?? '';
  }
  return ''
})


const rotation = ref(0);
function rotate(){
  rotation.value += 90;
  if(rotation.value === 360){
    rotation.value = 0;
  }
  console.log("rotation value: ", rotation.value);
}

defineExpose({
  rotate,
});

const currentPage = ref(1);

const handlePageChanged = (page: number) => {
  currentPage.value = page;
}
</script>

<style scoped>
ion-title {
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}
ion-toolbar {
  padding: 0 5%;
  --ion-safe-area-top: 8%;
}

.close-icon {
  box-sizing: content-box;
  position: absolute;
  right: 4%;
  top: 4%;
  width: 8%;
  aspect-ratio: 1 / 1;
  z-index: 2;
  cursor: pointer;

  background-color: rgba(255, 255, 255, 0.75);
  padding: 1%;
  border-radius: 50%;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.close-icon:hover{
  transform: scale(1.1);
}

.web .close-icon{
  width: 3%;
}

.pdf-wrapper{
  width: 100%;
  height: 100%;
  padding: 2%;
}

</style>
