import requestService from "@/services/requests";
import { useWorksheetData, IWorksheet, ITask, ITask0, IActivity, ProblemType, IFile, IProblem, ITutorial } from "@/modules/a.worksheets/stores/worksheetDataStore";
import { useUserAnswersData, ISolutionDisplayOption, IAnswerWrapper, IPermissions, IAssessment, Reviewer, EvaluationScale, IOpenEndedAnswer, IClosedEndedAnswer } from "@/modules/a.worksheets/stores/wksUserAnswersDataStore";
import { provide, inject, InjectionKey, computed, ComputedGetter, ComputedSetter, ComputedRef, WritableComputedRef, reactive, ref, watch } from 'vue';
import { IMedia, MediaType } from "@/modules/a.worksheets/helpers/mediaHandler";
import { WorksheetProblemResolutionResponseClosed, worksheetService, WorksheetProblemResolutionResponseOpen } from "@/modules/a.worksheets/services/worksheet.service";
import { Axios, AxiosResponse } from "axios";
import { setLoadingSpinner, spinnerManager } from "@/globals/pages/spinnerControl";
import Delta from 'quill-delta';

type SolutionData = MediaType | 'text' | 'yesno' | 'char' | "delta";


function getWorksheetStatus(usr_uuid: string, wks_uuid: string, course_uuid: string) {
    const params = { wks_uuid: wks_uuid, usr_uuid: usr_uuid, course_uuid: course_uuid };
    const resp = requestService.getWithParams("user-worksheet-status", params)
    return resp;
}

interface IWorksheetHandler {
    wksName: ComputedRef<string>;
    wksUUID: ComputedRef<string>;
    wksExtraInfoFile: ComputedRef<IFile | null>;
    wksExtraInfoFilename: ComputedRef<string | null>
    permissions: ComputedRef<IPermissions>;
    selectedDisplayOption: WritableComputedRef<ISolutionDisplayOption>;
    taskUUIDs: ComputedRef<Array<string>>;
    activityUUIDs: ComputedRef<Array<string>>;

    task: {
        get(taskUUID: string): ITask0 | undefined;
        select(taskUUID: string): boolean;
        isCompleted: (taskUUID: string) => boolean;
    }

    activity: {
        get(taskUUID: string, actUUID: string): IActivity | undefined;
        select(actUUID: string): boolean;

        isCompleted: (taskUUID: string, actUUID: string) => boolean;
        isProblem: (taskUUID: string, actUUID: string) => boolean;
        getProblemType: (taskUUID: string, actUUID: string) => ProblemType | undefined;
        getProblem: (taskUUID: string, actUUID: string) => IProblem | undefined;
        isTutorial: (taskUUID: string, actUUID: string) => boolean;
        getTutorial: (taskUUID: string, actUUID: string) => ITutorial | undefined;

        // -------------------- Answer related
        sendAnswer: (taskUUID: string, actUUID: string, data: Array<File> | string | boolean, type: SolutionData) => Promise<AxiosResponse | undefined>;
        getAnswer: (taskUUID: string, actUUID: string) => IAnswerWrapper | undefined;


        isAssessment: (taskUUID: string, actUUID: string, who: Reviewer) => boolean;
        getAssessment: (taskUUID: string, actUUID: string, who: Reviewer) => IAssessment | undefined;

        sendSelfAssessment: (taskUUID: string, actUUID: string, scale: number, grade: string) => Promise<AxiosResponse | undefined>;
        sendPeerAssessment: (taskUUID: string, actUUID: string, scale: number, grade: string) => Promise<AxiosResponse | undefined>;
        sendTeacherAssessment: (taskUUID: string, actUUID: string, scale: number, grade: string) => Promise<AxiosResponse | undefined>;
    }

    $: {
        task: {
            instance: ComputedRef<ITask0 | undefined>;
            uuid: ComputedRef<string>;
            isCompleted: ComputedRef<boolean>;
        }
        activity: {
            instance: ComputedRef<IActivity | undefined>;
            uuid: ComputedRef<string>;
            isCompleted: ComputedRef<boolean>;
            isProblem: ComputedRef<boolean>;
            problem: ComputedRef<IProblem | undefined>;
            problemType: ComputedRef<ProblemType | undefined>;
            isTutorial: ComputedRef<boolean>;
            tutorial: ComputedRef<ITutorial | undefined>;

            answer: ComputedRef<IAnswerWrapper | undefined>;
            isSelfAssessment: ComputedRef<boolean>;
            getSelfAssessment: ComputedRef<IAssessment | undefined>;
            isPeerAssessment: ComputedRef<boolean>;
            getPeerAssessment: ComputedRef<IAssessment | undefined>;
            isTeacherAssessment: ComputedRef<boolean>;
            getTeacherAssessment: ComputedRef<IAssessment | undefined>;
        }
        files: {
            question: ComputedRef<Array<IFile>>;
            studentAnswer: ComputedRef<Array<IFile>>;
            teacherAnswer: ComputedRef<Array<IFile>>;
            tutorial: ComputedRef<Array<IFile>>;
        }
    }
}


class WorksheetHandlerClass implements IWorksheetHandler {
    private readonly _answerStore: ReturnType<typeof useUserAnswersData>;
    private readonly _wksStore: ReturnType<typeof useWorksheetData>;

    private events = reactive(new Map());

    constructor() {
        this._answerStore = useUserAnswersData();
        this._wksStore = useWorksheetData();
    }


    public wksName = computed(() => this._wksStore.name);
    public wksUUID = computed(() => this._wksStore.uuid);
    public wksExtraInfoFile = computed(() => this._wksStore.extra_info);
    public permissions = computed(() => this._answerStore.permissions);
    public wksAnsUUID = computed(() => this._answerStore.uuid);
    public wksExtraInfoFilename = computed(() => {
        return this._wksStore.extra_info?.filename ?? null;
    });

    public selectedDisplayOption = computed<ISolutionDisplayOption>({
        get: (() => this._answerStore.selectedDisplayOption) as ComputedGetter<ISolutionDisplayOption>,
        set: ((v: ISolutionDisplayOption) => (this._answerStore.selectedDisplayOption = v)) as ComputedSetter<ISolutionDisplayOption>,
    });
    taskUUIDs = computed(() => this._wksStore.getTasksList)
    activityUUIDs = computed(() => this._wksStore.getActivitiesList)

    public task = {
        get: (taskUUID: string): ITask0 | undefined => this._wksStore.getTask(taskUUID),
        select: (taskUUID: string): boolean => this._wksStore.selectTask(taskUUID),
        isCompleted: (taskUUID: string): boolean => {
            const task = this._wksStore.getTask(taskUUID);
            if (task) {
                const activitiesWithoutTutorials = task.task.activities.filter(activity => activity.activity_type === 1);
                console.log("activitiesWithoutTutorials: ", activitiesWithoutTutorials)
                if (activitiesWithoutTutorials.length === 0) return false;

                const getIsTaskCompleted = activitiesWithoutTutorials.every(activity => {
                    const problem = activity.problem;
                    if (!problem) return false; //Is there is not problem, then its a tutorial and 'completed'
                    return this.activity.isCompleted(taskUUID, activity.uuid)
                })
                console.log("getIsTaskCompleted: ", getIsTaskCompleted);
                return getIsTaskCompleted;
            }
            return false
        },
    }

    public activity = {
        get: (taskUUID: string, actUUID: string): IActivity | undefined => this._wksStore.getActivity(taskUUID, actUUID),
        select: (actUUID: string): boolean => this._wksStore.selectActivity(actUUID),

        isCompleted: (taskUUID: string, actUUID: string): boolean => {
            const activity = this._wksStore.getActivity(taskUUID, actUUID);

            const problem = activity?.problem;
            const problemUUID = problem?.uuid
            if (!problemUUID) return false
            return this._answerStore.isAnswer(problemUUID)
        },

        isProblem: (taskUUID: string, actUUID: string) => this._wksStore.getActivity(taskUUID, actUUID)?.activity_type === 1,
        getProblemType: (taskUUID: string, actUUID: string): ProblemType | undefined => {
            const act = this.activity.get(taskUUID, actUUID)
            if (act && act.problem) return act.problem?.problem_type
            else return undefined
        },
        getProblem: (taskUUID: string, actUUID: string): IProblem | undefined => this._wksStore.getActivity(taskUUID, actUUID)?.problem,
        isTutorial: (taskUUID: string, actUUID: string): boolean => this._wksStore.getActivity(taskUUID, actUUID)?.activity_type === 2,
        getTutorial: (taskUUID: string, actUUID: string): ITutorial | undefined => this._wksStore.getActivity(taskUUID, actUUID)?.tutorial,

        getAnswer: (taskUUID: string, actUUID: string): IAnswerWrapper | undefined => {
            // console.log('\t----getAnswer----')
            const activity = this._wksStore.getActivity(taskUUID, actUUID);
            // console.log("getAnswer - activity: ", activity)
            const problem = activity?.problem;
            // console.log("getAnswer - problem: ", problem)
            const problemUUID = problem?.uuid
            if (!problemUUID) return undefined
            const answer = this._answerStore.getAnswer(problemUUID)
            // console.log("getAnswer - answer: ", answer)
            // console.log("'\t----getAnswer END----'")
            return answer;
        },
        getAnswerForStudent: (taskUUID: string, actUUID: string, studentUUID: string): IAnswerWrapper | undefined => {
            const activity = this._wksStore.getActivity(taskUUID, actUUID);
            if (!activity || !activity.problem || !activity.problem.uuid) return undefined;
            const problemUUID = activity.problem.uuid;

            return this._answerStore.getAllAnswers.find(
                (answer: IAnswerWrapper) =>
                    answer.problem === problemUUID && (answer as any).studentUUID === studentUUID
            );
        },
          
        sendAnswer: async (taskUUID: string, actUUID: string, data: Delta | string | boolean | File[], type: SolutionData): Promise<AxiosResponse<WorksheetProblemResolutionResponseOpen | WorksheetProblemResolutionResponseClosed, any> | undefined> => {
            const wks_uuid = this.wksUUID.value;
            const wks_ans_uuid = this.wksAnsUUID.value;

            const problem_uuid = this.activity.get(taskUUID, actUUID)?.problem?.uuid

            let response: AxiosResponse<WorksheetProblemResolutionResponseOpen | WorksheetProblemResolutionResponseClosed> | undefined = undefined;
            if (!problem_uuid) return undefined;

            console.log("Data envio:", data, type)


            if (data instanceof Array) {
                // set spinner to load
                spinnerManager.registerTask('sendAnswer')
                try {
                    switch (type) {
                        case 'audio':
                            response = await worksheetService.postProblemResolutionAudio(wks_ans_uuid, problem_uuid, data);
                            break;
                        case 'video':
                            response = (await worksheetService.postProblemResolutionVideo(wks_ans_uuid, problem_uuid, data));
                            break;
                        case 'image':
                            response = (await worksheetService.postProblemResolutionPhoto(wks_ans_uuid, problem_uuid, data));
                            break;
                        case 'pdf':
                            response = (await worksheetService.postProblemResolutionPdf(wks_ans_uuid, problem_uuid, data));
                            break;
                        case 'document':
                            response = (await worksheetService.postProblemResolutionDocument(wks_ans_uuid, problem_uuid, data));
                            break;
                        default:
                            break;
                    }
                } catch (e) {
                    spinnerManager.unregisterTask('sendAnswer')
                    console.error(e);
                }


                spinnerManager.unregisterTask('sendAnswer')
            } else if (typeof data === 'string') {
                if (type === 'char') {
                    response = (await worksheetService.postProblemResolutionChoices(wks_ans_uuid, problem_uuid, data));
                } else {
                    response = (await worksheetService.postProblemResolutionText(wks_ans_uuid, problem_uuid, data));
                }
            } else if (typeof data === 'boolean') {
                response = (await worksheetService.postProblemResolutionYesNo(wks_ans_uuid, problem_uuid, data));
            } else if (type === "text") {
                response = (await worksheetService.postProblemResolutionDelta(wks_ans_uuid, problem_uuid, data));
            }
            if (response?.status === 200) {
                if (this.$.activity.problemType.value === ProblemType.OPEN) {
                    this.activity.setOpenAnswer(taskUUID, actUUID, response.data as WorksheetProblemResolutionResponseOpen)
                } else {
                    this.activity.setClosedAnswer(taskUUID, actUUID, response.data as WorksheetProblemResolutionResponseClosed)
                }

                if (response.data.router.includes(':')) {
                    this.event.emit('finish-router', response.data.router)
                }
            }
            return response;
        },
        setClosedAnswer: (taskUUID: string, actUUID: string, data: WorksheetProblemResolutionResponseClosed) => {
            const problemUUID = this.activity.get(taskUUID, actUUID)?.problem?.uuid
            const problemType: ProblemType | undefined = this.activity.getProblemType(taskUUID, actUUID);
            if (!problemUUID || !problemType) return;

            const close_ended: IClosedEndedAnswer = {
                student_answer: data.student_answer,
            }

            const wrappedAnswer: IAnswerWrapper = {
                uuid: "data.uuid", // TODO: get UUID from closed answer response
                solved_at: data.solved_at,
                problem: problemUUID,
                answer: {
                    open_ended: null,
                    close_ended: close_ended
                }
            }

            this._answerStore.setAnswer(wrappedAnswer)
        },
        setOpenAnswer: (taskUUID: string, actUUID: string, data: WorksheetProblemResolutionResponseOpen) => {

            const problemUUID = this.activity.get(taskUUID, actUUID)?.problem?.uuid
            const problemType: ProblemType | undefined = this.activity.getProblemType(taskUUID, actUUID);
            if (!problemUUID || !problemType) return;

            const open_ended: IOpenEndedAnswer = {
                uuid: data.uuid,
                input_type: data.input_type,
                answer_files: data.answer_file?.map((diffFile) => {
                    return {
                        uuid: diffFile.uuid,
                        order: diffFile.order,
                        file: diffFile.file,
                        textfield: diffFile.textfield,
                        textrtf: diffFile.textrtf
                    }
                }) ?? [],
                answer_assessment: data.answer_assessment === null ? [] : data.answer_assessment
            }


            const wrappedAnswer: IAnswerWrapper = {
                uuid: data.uuid,
                solved_at: data.solved_at,
                problem: problemUUID,
                answer: {
                    open_ended: open_ended,
                    close_ended: null
                }
            }
            this._answerStore.setAnswer(wrappedAnswer)
        },

        isAssessment: (taskUUID: string, actUUID: string, who: Reviewer): boolean => {
            const answer = this.activity.getAnswer(taskUUID, actUUID);
            const isSelfAssessment = answer?.answer?.open_ended?.answer_assessment?.some((assessment) => assessment.who_reviewed === who)
            return isSelfAssessment ?? false;
        },
        getAssessment: (taskUUID: string, actUUID: string, who: Reviewer): IAssessment | undefined => {
            const answer = this.activity.getAnswer(taskUUID, actUUID);
            return answer?.answer?.open_ended?.answer_assessment.find((assessment) => assessment.who_reviewed === who);
        },

        sendSelfAssessment: async (taskUUID: string, actUUID: string, scale: number, grade: string): Promise<AxiosResponse<Array<IAssessment>, any> | undefined> => {
            // We can only self-assess if its an answer of type open_ended
            const answer = this.activity.getAnswer(taskUUID, actUUID)
            const openEndedAnswerUUID = answer?.answer?.open_ended?.uuid
            if (!openEndedAnswerUUID) {
                console.error('openEndedAnswerUUID: ', openEndedAnswerUUID)
                return undefined;
            }
            return worksheetService.postSelfAssessment(openEndedAnswerUUID, scale, grade)
        },
        setSelfAssessment: (taskUUID: string, actUUID: string, data: Array<IAssessment>) => {
            const answer = this.activity.getAnswer(taskUUID, actUUID)
            if (!answer || !answer.answer || !answer.answer.open_ended) return
            answer.answer.open_ended.answer_assessment = data
        },

        sendPeerAssessment: async (taskUUID: string, actUUID: string, scale: number, grade: string): Promise<AxiosResponse<any, any> | undefined> => {
            const answer = this.activity.getAnswer(taskUUID, actUUID)
            const openEndedAnswerUUID = answer?.answer?.open_ended?.uuid
            if (!openEndedAnswerUUID) {
                console.error('openEndedAnswerUUID: ', openEndedAnswerUUID)
                return undefined;
            }
            return worksheetService.postPeerAssessment(openEndedAnswerUUID, scale, grade)
        },
        setPeerAssessment: (taskUUID: string, actUUID: string, data: Array<IAssessment>) => {
            const answer = this.activity.getAnswer(taskUUID, actUUID)
            if (!answer || !answer.answer || !answer.answer.open_ended) return
            answer.answer.open_ended.answer_assessment = data
        },
        sendTeacherAssessment: async (taskUUID: string, actUUID: string, scale: number, grade: string): Promise<AxiosResponse<any, any> | undefined> => {
            const answer = this.activity.getAnswer(taskUUID, actUUID)
            const openEndedAnswerUUID = answer?.answer?.open_ended?.uuid
            if (!openEndedAnswerUUID) {
                console.error('openEndedAnswerUUID: ', openEndedAnswerUUID)
                return undefined;
            }
            return worksheetService.postTeacherAssessment(openEndedAnswerUUID, scale, grade);
        },
        setTeacherAssessment: (taskUUID: string, actUUID: string, data: Array<IAssessment>) => {
            const answer = this.activity.getAnswer(taskUUID, actUUID)
            if (!answer || !answer.answer || !answer.answer.open_ended) return
            answer.answer.open_ended.answer_assessment = data
        },
        next: () => {
            const actIndex = this.activityUUIDs.value.findIndex((actUUID) => actUUID === this.$.activity.uuid.value)
            const taskIndex: number = this.taskUUIDs.value.findIndex((taskUUID) => taskUUID === this.$.task.uuid.value)
            if (actIndex !== -1 && actIndex < this.activityUUIDs.value.length) {
                this._wksStore.selectActivity(this.activityUUIDs.value[actIndex + 1])
            } else if (taskIndex !== -1 && taskIndex < this.taskUUIDs.value.length) {
                this._wksStore.selectTask(this.taskUUIDs.value[taskIndex + 1])
            }
        }
    }

    public event = {
        emit: (event: string, ...args: any[]) => {
            const callbacks = this.events.get(event);
            if (callbacks) {
                // eslint-disable-next-line @typescript-eslint/ban-types
                callbacks.forEach((callback: Function) => callback(...args));
            }
        },
        // eslint-disable-next-line @typescript-eslint/ban-types
        on: (event: string, callback: Function) => {
            if (!this.events.get(event)) {
                this.events.set(event, []);
            }
            this.events.get(event)?.push(callback);
        },
        // eslint-disable-next-line @typescript-eslint/ban-types
        off: (event: string, callback: Function) => {
            const callbacks = this.events.get(event);
            if (callbacks) {
                const index = callbacks.indexOf(callback);
                if (index >= 0) {
                    callbacks.splice(index, 1);
                }
            }
        }
    }

    $ = {
        task: {
            instance: computed(() => this._wksStore.getCurrentTask),
            uuid: computed(() => this._wksStore.selectedTaskUUID),
            isCompleted: computed(() => this.task.isCompleted(this._wksStore.selectedTaskUUID)),
        },
        activity: {
            instance: computed<IActivity | undefined>(() => this._wksStore.getCurrentActivity),
            uuid: computed<string>(() => this._wksStore.selectedActivityUUID),
            isCompleted: computed(() => this.activity.isCompleted(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID)),
            isProblem: computed(() => this.activity.isProblem(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID,)),
            problem: computed(() => this._wksStore.getCurrentActivity?.problem),
            problemType: computed(() => this.activity.getProblemType(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID)),
            isTutorial: computed(() => this.activity.isTutorial(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID)),
            tutorial: computed(() => this._wksStore.getCurrentActivity?.tutorial),
            answer: computed(() => {
                // console.log("----Worksheet Handler----")
                // console.log("selectedTaskUUID: ", this._wksStore.selectedTaskUUID)
                // console.log("selectedActivityUUID: ", this._wksStore.selectedActivityUUID)
                const fetchedActivity = this.activity.getAnswer(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID)
                // console.log("fetched activity: ", fetchedActivity);
                // console.log("----Worksheet Handler END----")
                return fetchedActivity
            }),

            isSelfAssessment: computed(() => this.activity.isAssessment(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID, Reviewer.SELF)),
            getSelfAssessment: computed(() => this.activity.getAssessment(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID, Reviewer.SELF)),
            isPeerAssessment: computed(() => this.activity.isAssessment(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID, Reviewer.PEER)),
            getPeerAssessment: computed(() => this.activity.getAssessment(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID, Reviewer.PEER)),
            isTeacherAssessment: computed(() => this.activity.isAssessment(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID, Reviewer.TEACHER)),
            getTeacherAssessment: computed(() => this.activity.getAssessment(this._wksStore.selectedTaskUUID, this._wksStore.selectedActivityUUID, Reviewer.TEACHER)),
        },
        files: {
            question: computed<IFile[]>(() => {
                return this._wksStore.getCurrentTask?.task.task_files?.map(tf => tf.file) ?? []
            }),
            studentAnswer: computed<IFile[]>(() => {
                const files = this._answerStore.getAllAnswers.flatMap(answer => answer.answer?.open_ended?.answer_files.map(af => af.file)) ?? []
                // Filter out null and undefined values
                const filteredFiles = files.filter(file => file !== null && file !== undefined);
                return filteredFiles as IFile[];
            }),
            teacherAnswer: computed<IFile[]>(() => {
                const solutions =
                    this._wksStore.getCurrentActivity?.problem?.teacher_solution ?? []

                return solutions
                    .flatMap(ts =>
                        ts.solution_files.map(sf => sf?.file)
                    )
                    .filter((file): file is IFile => !!file?.filename)
            }),
            tutorial: computed<IFile[]>(() => {
                return this._wksStore.getCurrentActivity?.tutorial?.tutorialFiles?.map(tf => tf.file) ?? []
            })
        }
    }
}

async function pauseAllVideos() {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
        if (!video.paused) {
            video.pause();
        }
    });
}

const worksheetHandlerInstance = new WorksheetHandlerClass();

// Define the injection key
const WorksheetKey: InjectionKey<WorksheetHandlerClass> = Symbol('WorksheetHandler');

// Function to provide the Worksheet instance
export function provideWorksheet() {
    provide(WorksheetKey, worksheetHandlerInstance);
}

// Function to inject the Worksheet instance
export function useWorksheetHandler() {

    const injectedWorksheet = inject(WorksheetKey);

    if (!injectedWorksheet) {
        throw new Error('No Worksheet instance provided!');
    }
    return injectedWorksheet;
}

export { getWorksheetStatus, ProblemType, SolutionData, pauseAllVideos }